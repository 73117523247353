
























import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class Contact extends Vue {
  private email: string = '';

  private async created() {
    const { data } = await axios.get('/admin/api/info');
    this.email = data.email;    
  }
}
