





























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '../components/Scroll.vue';
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import axios from 'axios';

interface Work {
  id: string;
  caption: string;
  imgUrl: string;
}

Swiper.use([Navigation]);

@Component({
  components: {
    Scroll
  },
})

export default class WorkItem extends Vue {
  @Mutation('setCurrentWorkYear', {namespace: 'Info'}) private setCurrentWorkYear: (year: string) => void;

  private nextId: Number = -1;
  private prevId: Number = -1;
  private workList: Work[] = [];

  private swiperOptions: any = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  private mySwiper: any = null;

  private async mounted() {
    await this.fetch();
    await this.fetchWorkList();
    this.$nextTick(() => {
      this.mySwiper = new Swiper(this.$refs.mySwiper as HTMLElement, {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });
  }

  private async fetch() {
    const { data } = await axios.get(`/admin/api/work/${this.$router.currentRoute.params.id}`);
    this.setCurrentWorkYear(data.work_date);

    data.images.forEach((imageItem) => {
      this.workList.push({
        id: imageItem.file_name,
        caption: imageItem.caption,
        imgUrl: `${document.location.origin}/${imageItem.file_name}`
      })
    });
  }
  
  private async fetchWorkList() {
    const { data } = await  axios.get(`/admin/api/work/sibling/${this.$router.currentRoute.params.id}`);

    this.nextId = data.nextId;
    this.prevId = data.prevId;
  }
}
