





























import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';

@Component({
  components: {
  },
})

export default class Admin extends Vue {
}
