


















































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '../components/Scroll.vue';
import axios from 'axios';

@Component({
  components: {
    Scroll
  },
})

export default class CV extends Vue {
  private currentLanguage: string = 'korean';
  private koreanText: string = '';
  private englishText: string = '';

  private async created() {
    const { data } = await axios.get('/admin/api/cv');
    this.koreanText = data.kor_text;
    this.englishText = data.eng_text;
  }

  private switchLanguage() {
    if (this.currentLanguage === 'korean') {
      this.currentLanguage = 'english';
    } else {
      this.currentLanguage = 'korean';
    }
  }
}
