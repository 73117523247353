









































































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '../components/Scroll.vue';
import axios from 'axios';

interface TextItem {
  id: number;
  title: string;
  bodyText: string;
}

@Component({
  components: {
    Scroll
  },
})

export default class Texts extends Vue {
  private currentText: TextItem = null;

  private textList: TextItem[] = [];

  private isShowAside: boolean = false;

  private created() {
    this.fetch();
  }

  private mounted() {
    this.switchText(this.textList[0]);
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/text');
    if (data.length > 0) {
      data.forEach((textItem) => {
        this.textList.push({
          id: textItem.id,
          title: textItem.title,
          bodyText: textItem.content,
        });
      });

      this.switchText(this.textList[0]);
    }
  }

  private switchText(newTextItem: TextItem) {
    this.currentText = newTextItem;
    this.isShowAside = false;
    this.scrollTop();
  }

  private switchAside() {
    this.isShowAside = !this.isShowAside;
  }

  private scrollTop() {
    this.$nextTick(() => {
      if (!!this.$refs.scroll) {
        (this.$refs.scroll as Scroll).scrollTo(0);
      }
    });
  }
}
