


























































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class AdminWorkUpload extends Vue {
  private imgDataList: (string | ArrayBuffer)[] = [];
  private imgFileList: (string | Blob)[] = [];

  private handleFileChange(e: Event) {
    for (let i = 0; i < (e.target as any).files.length; i++) {
      this.createImage((e.target as any).files[i]);
      this.imgFileList.push((e.target as any).files[i]);
    }
  }

  private createImage(file) {
    const image = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      this.imgDataList.push(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  private async upload() {
    const formData = new FormData();
    this.imgFileList.forEach((imgFile) => {
      formData.append("image", imgFile);
    });

    try {
      await axios.post('/admin/api/image/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('전송 완료');
      this.$router.push({ name: 'admin' });
    } catch {
      alert('전송 실패');
    }
  }
}
