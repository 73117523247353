import { render, staticRenderFns } from "./AdminExhibitionUpload.vue?vue&type=template&id=230e4f80&scoped=true&"
import script from "./AdminExhibitionUpload.vue?vue&type=script&lang=ts&"
export * from "./AdminExhibitionUpload.vue?vue&type=script&lang=ts&"
import style0 from "./AdminExhibitionUpload.vue?vue&type=style&index=0&id=230e4f80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230e4f80",
  null
  
)

export default component.exports