import Big from 'big.js';

export function Debounce(this: any, func: any, delay: number) {
  let id: any;

  return function(this: any, ...args: any[]) {
    clearTimeout(id);

    id = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export function Throttle(func: any, delay: number) {
  let id: any;

  return function(this: any, ...args: any) {
    if (!id) {
      func.apply(this, args);

      id = setTimeout(() => {
        id = null;
      }, delay);
    }
  };
}
