






























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '../components/Scroll.vue';
import axios from 'axios';

interface ExhibitionItem {
  id: string;
  title: string;
  imgUrl: string;
  date: string;
}

@Component({
  components: {
    Scroll
  },
})

export default class Exhibition extends Vue {
  @State('currentExhibitionType', {namespace: 'Info'}) private currentType: string;
  @Mutation('setCurrentExhibitionType', {namespace: 'Info'}) private setCurrentExhibitonType: (newType: string) => void;

  private soloExhibitionList: ExhibitionItem[] = [];
  private groupExhibitionList: ExhibitionItem[] = [];

  private isShowAside: boolean = false;

  private async created() {
    await this.fetchSolo();
    await this.fetchGroup();

    if(!!this.$route.params.type) {
      this.switchType(this.$route.params.type);
    }
  }

  private async fetchSolo() {
    const { data } = await axios.get('/admin/api/exhibition/solo');
    data.forEach((exhibitionItem) => {
      this.soloExhibitionList.push({
        id: exhibitionItem.id.toString(),
        title: exhibitionItem.title,
        imgUrl: `${document.location.origin}/${exhibitionItem.images[0].file_name}`,
        date: `${this.getFilteredDate(exhibitionItem.exhibition_start_date)} ~ ${this.getFilteredDate(exhibitionItem.exhibition_end_date)}`
      })
    });
  }

  private async fetchGroup() {
    const { data } = await axios.get('/admin/api/exhibition/group');
    data.forEach((exhibitionItem) => {
      this.groupExhibitionList.push({
        id: exhibitionItem.id.toString(),
        title: exhibitionItem.title,
        imgUrl: `${document.location.origin}/${exhibitionItem.images[0].file_name}`,
        date: `${this.getFilteredDate(exhibitionItem.exhibition_start_date)} ~ ${this.getFilteredDate(exhibitionItem.exhibition_end_date)}`
      })
    });
  }

  private getFilteredDate(date: string): string {
    return `${date.substr(0, 4)}/${date.substr(4, 2)}/${date.substr(6, 2)}`;
  }

  private switchType(newType: string) {
    this.setCurrentExhibitonType(newType);
    this.isShowAside = false;
  }

  private switchAside() {
    this.isShowAside = !this.isShowAside;
  }
  
}
