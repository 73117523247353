import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';

@Module({namespaced: true})
export default class InfoModule extends VuexModule {
  private workStartYear: number = null;
  private currentExhibitionType: string = 'solo';
  private currentWorkYear: string = new Date().getFullYear().toString();

  @Action({rawError: true})
  private async fetchInfo() {
    try {
      const { data } = await axios.get(`/admin/api/info`);
      this.context.commit('setWorkStartYear', data.work_start_year);
    } catch (err) {
      console.error(err);
    }
  }

  @Mutation
  private setCurrentWorkYear(year: string) {
    this.currentWorkYear = year;
  }

  @Mutation
  private setWorkStartYear(workStartYear: number) {
    this.workStartYear = workStartYear;
  }

  @Mutation
  private setCurrentExhibitionType(exhibitionType: string) {
    this.currentExhibitionType = exhibitionType;
  }
}
