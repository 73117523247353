























































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class AdminWork extends Vue {
  private workList: any[] = [];

  private created() {
    this.fetch();
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/work');
    this.workList = data;
  }

  private async deleteWorkItem(workId: number) {
    try {
      await axios.post('/admin/api/work/delete', { targetId: workId });
      alert('삭제완료');
      await this.fetch();
    } catch {
      alert('삭제 실패');
    }
  }

  private getImageUrl(fileName: string): string {
    return `${document.location.origin}/${fileName}`;
  }
}
