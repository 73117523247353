












































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '../components/Scroll.vue';
import axios from 'axios';

interface ExhibitionImage {
  id: string;
  caption: string;
  imgUrl: string;
}

@Component({
  components: {
    Scroll
  },
})

export default class ExhibitionItem extends Vue {
  @Mutation('setCurrentExhibitionType', {namespace: 'Info'}) private setCurrentExhibitonType: (newType: string) => void;

  private exhibitionTitle: string = '';
  private exhibitionText: string = '';
  private exhibitionImageList: ExhibitionImage[] = [];
  private exhibitionType: string = '';

  private mySwiper: any = null;

  private created() {
    this.fetch();
  }

  private async fetch() {
    const { data } = await axios.get(`/admin/api/exhibition/detail/${this.$router.currentRoute.params.id}`);
    this.exhibitionTitle = data.title;
    this.exhibitionText= data.content_text;
    this.exhibitionType = data.exhibition_type;
    this.setCurrentExhibitonType(this.exhibitionType);
    data.images.forEach((image) => {
      this.exhibitionImageList.push({
        id: image.file_name,
        caption: image.caption,
        imgUrl: `${document.location.origin}/${image.file_name}`
      });
    });
  }
}
