


























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import { Editor, EditorMenuBar, EditorContent } from 'tiptap';
import { Bold, Italic, Heading } from 'tiptap-extensions'
import axios from 'axios';

interface TextItem {
  title: string;
  content: string;
}

@Component({
  components: {
    EditorMenuBar, 
    EditorContent,
  },
})

export default class AdminText extends Vue {
  private textTitle: string = '';
  private editor: Editor = null;
  private html: string = '';
  private textList: TextItem[] = [];

  private created() {
    this.fetch();
  }

  private mounted() {
    this.$nextTick(() => {
      this.editor = new Editor({ 
        extensions: [ 
          new Bold(),
          new Italic(),
          new Heading()
        ],
        content: this.html,
        onUpdate: ({ getJSON, getHTML }) => {
          this.html = getHTML();
        },
      });
    });
  }

  private beforeDestroy() {
    this.editor.destroy();
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/text');
    this.textList = data;
  }

  private async upload() {
    await axios.post('/admin/api/text/upload', {
      textTitle: this.textTitle,
      textContent: this.html
    })
    alert('등록완료');
    this.textTitle = '';
    this.html = '';
    this.fetch();
  }

  private async deleteTextItem(textId: number) {
    await axios.post('/admin/api/text/delete', {
      targetId: textId
    });
    alert('삭제완료');
    this.fetch();
  }
}
