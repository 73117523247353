import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index.vue';
import Contact from './views/Contact.vue';
import CV from './views/CV.vue';
import Exhibition from './views/Exhibition.vue';
import ExhibitionItem from './views/ExhibitionItem.vue';
import Texts from './views/Texts.vue';
import Work from './views/Work.vue';
import WorkItem from './views/WorkItem.vue';

import Admin from './views/Admin/Admin.vue';
import AdminImage from './views/Admin/AdminImage.vue';
import AdminWork from './views/Admin/AdminWork.vue';
import AdminWorkUpload from './views/Admin/AdminWorkUpload.vue';
import AdminExhibition from './views/Admin/AdminExhibition.vue';
import AdminExhibitionUpload from './views/Admin/AdminExhibitionUpload.vue';
import AdminInfo from './views/Admin/AdminInfo.vue';
import AdminText from './views/Admin/AdminText.vue';
import AdminCV from './views/Admin/AdminCV.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/cv',
      name: 'cv',
      component: CV,
    },
    {
      path: '/exhibition',
      name: 'exhibition',
      component: Exhibition,
      props: true,
    },
    {
      path: '/exhibition/:id',
      name: 'exhibitionItem',
      component: ExhibitionItem,
    },
    {
      path: '/text',
      name: 'text',
      component: Texts,
    },
    {
      path: '/work',
      name: 'work',
      component: Work
    },
    {
      path: '/work/:id',
      name: 'workItem',
      component: WorkItem
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin
    },
    {
      path: '/admin/image',
      name: 'adminImage',
      component: AdminImage
    },
    {
      path: '/admin/cv',
      name: 'adminCV',
      component: AdminCV
    },
    {
      path: '/admin/exhibition',
      name: 'adminExhibition',
      component: AdminExhibition
    },
    {
      path: '/admin/exhibition/upload',
      name: 'adminExhibitionUpload',
      component: AdminExhibitionUpload
    },
    {
      path: '/admin/work',
      name: 'adminWork',
      component: AdminWork
    },
    {
      path: '/admin/work/upload',
      name: 'adminWorkUpload',
      component: AdminWorkUpload
    },
    {
      path: '/admin/text',
      name: 'adminText',
      component: AdminText
    },
    {
      path: '/admin/info',
      name: 'adminInfo',
      component: AdminInfo
    }
  ],
});
