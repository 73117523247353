







































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class Index extends Vue {
  private aritstEngName: string = '';

  private created() {
    this.fetch();
  }

  private getIndexImageUrl() {
    return `${document.location.origin}/index.jpg`;
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/info');
    this.aritstEngName = data.eng_name;
  }
}
