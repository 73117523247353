

























































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '@/components/Scroll.vue';
import axios from 'axios';

@Component({
  components: {
    Scroll
  },
})

export default class AdminWorkUpload extends Vue {
  private workTitle: string = '';
  private workDate: string = '';
  private imgDataList: (string | ArrayBuffer)[] = [];
  private imgFileList: (string | Blob)[] = [];
  private captionList: string [] = [];

  private handleFileChange(e: Event) {
    for (let i = 0; i < (e.target as any).files.length; i++) {
      this.createImage((e.target as any).files[i]);
      this.imgFileList.push((e.target as any).files[i]);
      this.captionList.push[''];
    }
  }

  private createImage(file) {
    const image = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      this.imgDataList.push(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  private async upload() {
    if (this.validate()) {
      const formData = new FormData();
      this.imgFileList.forEach((imgFile) => {
        formData.append("image", imgFile);
      })
      formData.append('workTitle', this.workTitle);
      formData.append('workDate', this.workDate);
      formData.append('workCaptions', JSON.stringify(this.captionList));
      try {
        await axios.post('/admin/api/work/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        alert('전송 완료');
        this.$router.push({ name: 'adminWork' });
      } catch {
        alert('전송 실패');
      }
    } else {
      alert('필수 입력 누락 또는 날짜 잘못 입력');
    }
  }

  private validate(): boolean {
    if (this.workDate.length !== 4 ) {
      return false;
    } else {
      return true;
    }
  }
}
