

















































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class AdminInfo extends Vue {
  private koreanName: string = '';
  private englishName: string = '';
  private email: string = '';
  private workStartYear: number = 1000;

  private created() {
    this.fetch();
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/info');
    this.koreanName = data.kor_name;
    this.englishName = data.eng_name;
    this.email = data.email;
    this.workStartYear = data.work_start_year;
  }

  private async modify() {
    try {
      await axios.post('/admin/api/info/modify', {
        koreanName: this.koreanName,
        englishName: this.englishName,
        email: this.email,
        workStartYear: this.workStartYear
      });
      alert('변경 완료');
    } catch {
      alert('변경 실패');
    }
  }
}
