

































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '@/components/Scroll.vue';
import axios from 'axios';
import { Editor, EditorMenuBar, EditorContent } from 'tiptap';
import { Bold, Italic, Heading } from 'tiptap-extensions'

@Component({
  components: {
    Scroll,
    EditorMenuBar, 
    EditorContent,
  },
})

export default class AdminExhibitionUpload extends Vue {
  private exhibitionTitle: string = '';
  private exhibitionStartDate: string = '';
  private exhibitionEndDate: string = '';
  private exhibitionType: string = 'solo';
  private editor: Editor = null;
  private html: string = '';

  private imgDataList: (string | ArrayBuffer)[] = [];
  private imgFileList: (string | Blob)[] = [];
  private captionList: string [] = [];

  private mounted() {
    this.$nextTick(() => {
      this.editor = new Editor({ 
        extensions: [ 
          new Bold(),
          new Italic(),
          new Heading()
        ],
        content: this.html,
        onUpdate: ({ getJSON, getHTML }) => {
          this.html = getHTML();
        },
      });
    });
  }

  private beforeDestroy() {
    this.editor.destroy();
  }

  private handleFileChange(e: Event) {
    for (let i = 0; i < (e.target as any).files.length; i++) {
      this.createImage((e.target as any).files[i]);
      this.imgFileList.push((e.target as any).files[i]);
      this.captionList.push[''];
    }
  }

  private createImage(file) {
    const image = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      this.imgDataList.push(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  private async upload() {
    if (this.validate()) {
      const formData = new FormData();
      this.imgFileList.forEach((imgFile) => {
        formData.append("image", imgFile);
      })
      formData.append('exhibitionTitle', this.exhibitionTitle);
      formData.append('exhibitionStartDate', this.exhibitionStartDate);
      formData.append('exhibitionEndDate', this.exhibitionEndDate);
      formData.append('exhibitionType', this.exhibitionType);
      formData.append('exhibitionText', this.html);
      formData.append('exhibitionCaptions', JSON.stringify(this.captionList));
      try {
        await axios.post('/admin/api/exhibition/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        alert('전송 완료');
        this.$router.push({ name: 'adminExhibition' })
      } catch {
        alert('전송 실패');
      }
    } else {
      alert('필수 입력 누락 또는 날짜 잘못 입력');
    }
  }

  private validate(): boolean {
    if (this.exhibitionStartDate.length !== 8 || this.exhibitionEndDate.length !== 8) {
      return false;
    } else {
      return true;
    }
  }
}
