

























































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import Scroll from '../components/Scroll.vue';
import axios from 'axios';
import VueMasonry from 'vue-masonry-css';

Vue.use(VueMasonry);

interface WorkYearItem {
  year: string;
  workList: WorkItem[];
}

interface WorkItem {
  id: number;
  title: string;
  imgUrl: string;
}

@Component({
  components: {
    Scroll
  },
})

export default class Work extends Vue {
  @State('workStartYear', {namespace: 'Info'}) private startYear: number;
  @State('currentWorkYear', {namespace: 'Info'}) private currentWorkYear: string;
  @Mutation('setCurrentWorkYear', {namespace: 'Info'}) private setCurrentWorkYear: (year: string) => void;

  private workYearlyData: WorkYearItem[] = [];
  private currentWorkYearItem: WorkYearItem = null;
  private yearList: string[] = [];
  private colNumber: number = 4;

  private created() {
    this.fetch();
  }

  private mounted() {
    if (window.innerWidth < 1024 && window.innerWidth > 641) {
      this.colNumber = 3;
    } else if (window.innerWidth < 640 && window.innerWidth > 481) {
      this.colNumber = 2;
    } else if (window.innerWidth < 480) {
      this.colNumber = 1;
    }
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/work');

    this.setWorkYearlyData(data);
  }


  private setWorkYearlyData(data) {
    const currentYear: number = new Date().getFullYear();
    for (let i = currentYear; i >=this.startYear; i--) {
      this.workYearlyData.push({
        year: i.toString(),
        workList: []
      });
      this.yearList.push(i.toString());
    }
    const workYearlyData = this.workYearlyData;

    workYearlyData.forEach((workYearItem, yIndex) => {
      data.forEach((workItem) => {
        if (workYearItem.year === workItem.work_date) {
          workYearlyData[yIndex].workList.push({
            id: workItem.id,
            title: workItem.title,
            imgUrl: `${document.location.origin}/${workItem.images[0].file_name}`
          });
        }
      });

      workYearlyData[yIndex].workList.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
    });

    Vue.set(this, 'workYearlyData', workYearlyData);
    this.setCurrentWorkYearItem(this.currentWorkYear);
  }

  private setCurrentWorkYearItem(year: string) {
    this.workYearlyData.forEach((workYearItem) => {
      if (year === workYearItem.year) {
        this.currentWorkYearItem = workYearItem;
      }
    });
    this.setCurrentWorkYear(year);
  }
}
