



















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import { Editor, EditorMenuBar, EditorContent } from 'tiptap';
import { Bold, Italic, Heading } from 'tiptap-extensions'
import axios from 'axios';

@Component({
  components: {
    EditorMenuBar, 
    EditorContent,
  },
})

export default class AdminCV extends Vue {
  private korEditor: Editor = null;
  private engEditor: Editor = null;
  private korHtml: string = '';
  private engHtml: string = '';

  private created() {
    this.fetch();
  }

  private initTipTap() {
    this.$nextTick(() => {
      this.korEditor = new Editor({ 
        extensions: [ 
          new Bold(),
          new Italic(),
          new Heading()
        ],
        content: this.korHtml,
        onUpdate: ({ getJSON, getHTML }) => {
          this.korHtml = getHTML();
        },
      });

    this.engEditor = new Editor({ 
        extensions: [ 
          new Bold(),
          new Italic(),
          new Heading()
        ],
        content: this.engHtml,
        onUpdate: ({ getJSON, getHTML }) => {
          this.engHtml = getHTML();
        },
      });
    });
  }

  private beforeDestroy() {
    this.korEditor.destroy();
    this.engEditor.destroy();
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/cv');
    this.korHtml = data.kor_text;
    this.engHtml = data.eng_text;
    this.initTipTap();
  }
  
  private async send() {
    try {
      await axios.post('/admin/api/cv/modify', {
        koreanText: this.korHtml,
        englishText: this.engHtml
      });
      alert('변경 완료');
    } catch {
      alert('update 실패');
    }
  }
}
